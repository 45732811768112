import React, { useState, useEffect } from 'react';
import Select from 'react-select';

function ParametersForm() {
  const [partners, setPartners] = useState([]);
  const [consumers, setConsumers] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedConsumer, setSelectedConsumer] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [formData, setFormData] = useState({
    _id: '',
    LTV: '',
    maxLTV: '',
    invoiceCount: '',
    maxInvoiceCount: '',
    maxInvoiceValue: '',
    tenureMonths: '',
    maxTenureMonths: '',
    invoicesPaidOnTime: '',
    totalInvoices: ''
  });

  useEffect(() => {
    fetchPartners();
    fetchConsumers();
  }, []);

  const fetchParameters = async (partnerId, consumerId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}partner/get-parameters-by-consumer-and-partner-id?partnerId=${partnerId}&consumerId=${consumerId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (data.data) {
        setFormData({
          _id: data.data._id,
          LTV: data.data.LTV || '',
          maxLTV: data.data.maxLTV || '',
          invoiceCount: data.data.invoiceCount || '',
          maxInvoiceCount: data.data.maxInvoiceCount || '',
          maxInvoiceValue: data.data.maxInvoiceValue || '',
          tenureMonths: data.data.tenureMonths || '',
          maxTenureMonths: data.data.maxTenureMonths || '',
          invoicesPaidOnTime: data.data.invoicesPaidOnTime || '',
          totalInvoices: data.data.totalInvoices || ''
        });
      }
    } catch (error) {
      console.error('Error fetching parameters:', error);
    }
  };

  const handleSelectionChange = (type, selectedOption) => {
    if (type === 'partner') {
      setSelectedPartner(selectedOption);
    } else {
      setSelectedConsumer(selectedOption);
    }

    // Check if both partner and consumer are selected
    const partner = type === 'partner' ? selectedOption : selectedPartner;
    const consumer = type === 'consumer' ? selectedOption : selectedConsumer;

    if (partner && consumer) {
      fetchParameters(partner.value, consumer.value);
    } else {
      setFormData({
        _id: '',
        LTV: '',
        maxLTV: '',
        invoiceCount: '',
        maxInvoiceCount: '',
        maxInvoiceValue: '',
        tenureMonths: '',
        maxTenureMonths: '',
        invoicesPaidOnTime: '',
        totalInvoices: ''
      });
    }
  };

  const partnerOptions = partners.map(partner => ({
    value: partner._id,
    label: partner.name
  }));

  const consumerOptions = consumers.map(consumer => ({
    value: consumer._id,
    label: consumer.name
  }));

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#F85D5A' : '#E5E7EB',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#F85D5A'
      }
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
      outline: 'none !important',
      border: 'none !important',
      boxShadow: 'none !important',
      '*': {
        outline: 'none !important',
        border: 'none !important',
        boxShadow: 'none !important'
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      outline: 'none',
      border: 'none',
      boxShadow: 'none'
    }),
    container: (provided) => ({
      ...provided,
      outline: 'none',
      border: 'none',
      boxShadow: 'none'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#F85D5A' : provided.backgroundColor,
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: '#E74D4A',
        color: 'white'
      }
    })
  };

  const fetchPartners = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}partner/get-all-partners`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setPartners(data.data);
    } catch (error) {
      console.error('Error fetching partners:', error);
    }
  };

  const fetchConsumers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}consumer/get-all-consumers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setConsumers(data.data);
    } catch (error) {
      console.error('Error fetching consumers:', error);
    }
  };

  const handleInputChange = (e) => {
    const value = Math.max(0, Number(e.target.value));
    setFormData({
      ...formData,
      [e.target.name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!selectedPartner || !selectedConsumer) {
      alert('Please select both partner and consumer');
      return;
    }

    const submitData = {
      ...formData,
      partnerId: selectedPartner.value,
      consumerId: selectedConsumer.value
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}partner/create-parameters`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submitData)
      });

      const data = await response.json();

      if (response.ok) {
        setFormData({
          _id: '',
          LTV: '',
          maxLTV: '',
          invoiceCount: '',
          maxInvoiceCount: '',
          maxInvoiceValue: '',
          tenureMonths: '',
          maxTenureMonths: '',
          invoicesPaidOnTime: '',
          totalInvoices: ''
        });
        setSelectedPartner(null);
        setSelectedConsumer(null);
        setShowSuccessPopup(true);
        setTimeout(() => setShowSuccessPopup(false), 3000);
      } else {
        alert(data.message || 'Submission failed');
      }
    } catch (error) {
      alert('Error submitting form');
    }
  };

  return (
    <div className="min-h-screen border border-[#F85D5A] rounded-lg flex items-center justify-center p-4 relative">
      {showSuccessPopup && (
        <div className="fixed top-4 right-4 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg animate-[fadeInOut_3s_ease-in-out]">
          Parameters saved successfully!
        </div>
      )}
      
      <div className="w-full max-w-lg bg-white shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-bold mb-6 text-[#F85D5A]">Partner Parameters</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="partner" className="block text-gray-700 font-medium mb-1">
              Select Partner
            </label>
            <Select
              id="partner"
              options={partnerOptions}
              value={selectedPartner}
              onChange={(option) => handleSelectionChange('partner', option)}
              placeholder="Search partner..."
              styles={customStyles}
              isClearable
            />
          </div>

          <div className="mb-4">
            <label htmlFor="consumer" className="block text-gray-700 font-medium mb-1">
              Select Consumer
            </label>
            <Select
              id="consumer"
              options={consumerOptions}
              value={selectedConsumer}
              onChange={(option) => handleSelectionChange('consumer', option)}
              placeholder="Search consumer..."
              styles={customStyles}
              isClearable
            />
          </div>

          {Object.keys(formData).filter(key => key !== '_id').map((field) => (
            <div key={field} className="mb-4">
              <label htmlFor={field} className="block text-gray-700 font-medium mb-1">
                {field.replace(/([A-Z])/g, ' $1').trim()}
              </label>
              <input
                type="number"
                id={field}
                name={field}
                value={formData[field]}
                onChange={handleInputChange}
                placeholder={`Enter ${field.replace(/([A-Z])/g, ' $1').trim()}`}
                className="w-full border border-gray-300 rounded-lg p-2 focus:ring-[#F85D5A] focus:border-[#F85D5A]"
                min="0"
                required
              />
            </div>
          ))}

          <button
            type="submit"
            className="w-full bg-[#F85D5A] text-white font-semibold py-2 px-4 rounded-lg hover:bg-[#E74D4A] transition duration-200"
          >
            Save
          </button>
        </form>
      </div>
    </div>
  );
}

export default ParametersForm;
