
import React, { useState ,useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser,faEye,faEyeSlash, faBuilding } from '@fortawesome/free-solid-svg-icons';
/* import logoImage from "/BB.png"; */
import { useNavigate } from 'react-router-dom';


function UserLogin() {
    const [Username, setUsername] = useState('');
    const [Password, setPassword] = useState('');
    const navigate = useNavigate();
    const [InvalideEmail,setInvalidEmail]=useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [EmptyLoginData, setEmptyLoginData]= useState(false);
    const [UserNotFound,setUserNotFound]=useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [organizations, setOrganizations] = useState([]);

  
  
    const handleUsernameChange = (e) => {
      setUsername(e.target.value);
      setInvalidEmail(false); // Reset invalide password state when password changes 
        setEmptyLoginData(false); // Reset Empty login data state when password changes
        setUserNotFound(false);
    };
  
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
      setInvalidPassword(false); // Reset invalide password state when password changes 
        setEmptyLoginData(false); // Reset Empty login data state when password changes
        setUserNotFound(false);
    };
  
    const validateEmail = (email) => {
      const emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailValidation.test(email);
    };
  
    // Password validation logic
    const validatePassword = (password) => {
      const passwordValidation = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      return passwordValidation.test(password);
    };
  
    const handleLogin = async () => {
        const LoginUrl = `${process.env.REACT_APP_BUILDCOINS}user/user-login`;
      
        // Check if Username or Password is empty
        if (!Username || !Password) {
          setEmptyLoginData("Please enter Email and Password."); // Error message for empty fields
          return;
        }
      
        // Validate email and password
        if (!validateEmail(Username) /* || !validatePassword(Password) */) {
          setInvalidEmail(!validateEmail(Username)); // Mark email as invalid if it fails validation
          //setInvalidPassword(!validatePassword(Password)); // Mark password as invalid if it fails validation
          return;
        }
      
        try {
          const LoginData = {
            username: Username,
            password: Password,
          };
      
          console.log("Login Data:", LoginData);
      
          // Make the POST request
          const response = await fetch(LoginUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(LoginData),
          });
      
          // Handle the response
          if (response.ok) {
            const data = await response.json();
            console.log("Login Successful:", data);
      
            // Store data in localStorage and sessionStorage
            localStorage.setItem('LoginData', JSON.stringify(data));
            sessionStorage.setItem('userData', JSON.stringify(data));
            sessionStorage.setItem('userId', data.userId);
            sessionStorage.setItem('roleId', data.roleID);
            sessionStorage.setItem('roleName', data.roleName);
      
            // Check if a password change is required
            if (data) {
                navigate('/buildcoins-approval')
            } 
          } else {
            // Handle non-OK responses
            const errorData = await response.json();
            console.error("Login failed:", errorData);
            setUserNotFound('User not found');
          }
        } catch (error) {
          // Handle fetch or other errors
          console.error("Error during login:", error);
          setUserNotFound('An error occurred. Please try again later.');
        }
      };
      

  
    const handleForgotPassword = () => {
      navigate('/bb/ForgotPassword')
    };
  
    const togglePasswordVisibility = () => {
      setShowPassword((prev) => !prev);
    };


    



    return (
        <div className="w-full flex justify-center items-center h-screen font-roboto overflow-hidden relative">
            <div className="flex w-full justify-center items-center">
                <div className="flex flex-col space-y-4 justify-center w-full items-center">
                <div className='flex flex-row justify-center items-center' >
                <img src={'/BB.png'} alt="Logo" className='h-16 w-16 sm:w-14 sm:h-14'/>
                <p className='text-4xl sm:text-2xl md:text-4xl lg:text-5xl'>BUILDCOINS</p>
            </div>
                    <div className="flex-col w-full sm:w-full md:w-2/3 lg:w-1/2 xl:w-1/3 flex self-center items-center border border-[#D9D9D9] border-2 bg-opacity-[15%] rounded-sm p-4 sm:p-0">
                        <p className="text-2xl pt-4">LOGIN</p>
                        <div className="p-6 sm:px-0 relative">
                            <div className="flex-col w-full">
                            <div className='flex justify-center'>
                                <p className="text-md">User Name</p>
                                </div>
                                <div className="flex-row flex items-center justify-center space-x-2 pl-2 pr-2 w-full relative">
                                    <span className="flex justify-center items-center absolute left-5">
                                        <FontAwesomeIcon icon={faUser} className="text-gray-400" />
                                        <div className="ml-1 mr-4 flex-grow border-r border-gray-300 h-6"></div>
                                    </span>
                                    <input
                                    type="text"
                                    placeholder="Username"
                                        className="w-full sm:w-full md:w-full rounded-sm focus:outline-none focus:ring-[1px]  focus:ring-[#EB001B] text-gray-600 text-sm px-2 py-1 pr-2 pl-8 text-[12px] placeholder-text-xs placeholder-text-center border border-gray-300"
                                        value={Username}
                                      onChange={handleUsernameChange}
                                      onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                          e.preventDefault();
                                          const passwordInput = document.querySelector('input[type="password"]');
                                          if (passwordInput) {
                                            passwordInput.focus();
                                          }
                                        }
                                      }}
                                     
                                   />
                                    {InvalideEmail && (
                                    <div className="text-red-500 text-[12px]">
                                        <p className="flex justify-center">Enter the correct Email</p>
                                    </div>
                                    )}
                                    </div>
                                                                </div>
                                <div className='flex flex-col w-full mt-4'>
                                    <div className='flex justify-center'>
                                <p className="text-md">Password</p>
                                </div>
                                <div className="flex-row flex items-center space-x-2 relative justify-center pl-2 pr-2 w-full">
                                    <span className="flex justify-center items-center absolute left-5">
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="text-gray-400 cursor-pointer" onClick={togglePasswordVisibility} />
                                        <div className="ml-1 mr-4 flex-grow border-r border-gray-300 h-6"></div>
                                    </span>
                                    <input
                                    onChange={handlePasswordChange}
                                     type={showPassword ? "text" : "password"}
                                     placeholder="Password"
                                     value={Password}
                                    className="w-full sm:w-full md:w-full rounded-sm focus:outline-none focus:ring-[1px]  focus:ring-[#EB001B]  text-gray-600 text-sm px-2 py-1 pr-2 pl-8 text-[12px] placeholder-text-xs placeholder-text-center border border-gray-300"
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleLogin(); // Call the handleLogin function when Enter key is pressed
                                      }
                                    }}
                                     />
                                    {invalidPassword && (
                                        <div className="text-red-500 text-[12px]">
                                            <p>Password is incorrect</p>
                                        </div>
                                        )}
                                </div>
                                {EmptyLoginData || UserNotFound && (
                                <div className="text-red-500 text-[12px] flex justify-center items-center">
                                <p className="text-red-500">
                                    {EmptyLoginData}
                                    {UserNotFound}
                                </p>
                                </div>
                            )}
                            </div>
                          
                            <div className="flex justify-center items-center mt-3">
                                <p className="underline text-[#2158E5] hover:text-[#EB001B] cursor-pointer text-[14px]" onClick={handleForgotPassword}>Forgot Password?</p>
                            </div>
                            <div className="flex justify-center items-center mt-3">
                                <button className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white text-md rounded-sm px-4 py-1  mb-4"
                                onClick={handleLogin}>Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserLogin;
