import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import MenuBar from './menuBar';
function BuildCoinsApproval() {
  const navigate = useNavigate();

  const [showQR, setShowQR] = useState(false);
  const [qrLoading, setQrLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const consumerId = sessionStorage?.getItem('consumerId');
  const consumer = sessionStorage?.getItem('userData') ;
  const consumerName = JSON.parse(consumer)?.name;
  const [selectedPartner, setSelectedPartner] = useState(null);


  const [coinsData,setCoinsData] = useState([]);
  const getSequentialColor = (index) => {
    const colors = ['bg-blue-500', 'bg-green-500', 'bg-purple-500'];
    return colors[index % colors.length];
  };
  
  useEffect(() => {
    getConsumerRewards(partnerId);
  }, []);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const partnerId = sessionStorage?.getItem('userId') || sessionStorage?.getItem('partnerId');

  const getConsumerRewards = async (partnerId) => {
    try {
      if (!partnerId) {
        throw new Error("Consumer ID is required");
      }

      const apiUrl = `${process.env.REACT_APP_BUILDCOINS}rewards/get-coin-ledger-by-partner?partnerId=${partnerId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch consumer rewards");
      }

      const data = await response.json();
      console.log("data:",data.data)
      setCoinsData(data.data);  
      return data;
    } catch (error) {
      console.error("Error fetching consumer rewards:", error.message);
      throw error;
    }
  };

  const updateCoinLedgerStatus = async (coinLedgerId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}partner/update-coinledger-status?coinLedgerId=${coinLedgerId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to update coin ledger status');
      }
  
      return data;
    } catch (error) {
      console.error('Error updating coin ledger status:', error.message);
      throw error;
    }
  };

  
  return (
    <div>
    <div>
    <MenuBar/>
    </div> 
    <div className="min-h-screen mt-20 p-6">
      <div className="max-w-sm mx-auto bg-white">
      <div className="flex items-center justify-center gap-4 mb-4">
    <h2 className="text-3xl font-bold text-[#F85D5A] ">BuildCoins Approval</h2>
  </div>

        
            <div className="space-y-4">
            {coinsData.length === 0 ? (
              <div className="min-h-[300px] flex items-center justify-center border border-[#F85D5A]">
            <div className="text-center text-gray-600">
              No coins for approval.
            </div>
          </div>

            ) : (
                coinsData.map((item, index) => (
                    <div 
                      key={index} 
                      className="pb-4 hover:bg-gray-50 border border-[#F85D5A] rounded-lg p-2"
                    >
                      <div className="flex items-center mb-2">
                  
                        <div className="flex-1">
                        <div className="flex flex-col items-center space-y-3">
                            <span className="font-medium text-xl text-center">{item.partnerName}</span>
                            <span className="font-bold text-green-600 text-xl text-center">{item.buildCoins} coins</span>
                            <span className="text-gray-500 text-sm text-center">These coins are given for {item.consumerName} for invoice {item.invoiceNumber}</span>
                            <button
                            onClick={async () => {
                                try {
                                await updateCoinLedgerStatus(item.coinLedgerId);
                                setSelectedItem(item);
                                setIsModalOpen(true);
                                item.status = 'approved';
                                } catch (error) {
                                console.error('Error:', error);
                                }
                            }}
                            className={`${
                                item.status === 'approved' 
                                ? 'bg-green-500 hover:bg-green-600' 
                                : 'bg-[#F85D5A] hover:bg-[#F85D5A]'
                            } text-white px-2 py-1 rounded-lg transition duration-200`}
                            >
                            {item.status === 'approved' ? '✓ Approved' : 'Approve'}
                            </button>
                        </div>
                        </div>
                      </div>
                    </div>
                  ))
                  
            )}
          </div>
                </div>

      {/* Modal */}
      {isModalOpen && selectedItem && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full relative">

      <h2 className="text-xl font-bold mb-4">Approved</h2>
    
      <div className="mb-6 space-y-3">
        <div className="flex justify-center">
          <span className="font-medium">{selectedItem.buildCoins} coins credited for {selectedItem.consumerName}</span>
        </div>
      </div>

      <button
        className="w-full bg-[#F85D5A] text-white font-bold py-2 rounded-lg hover:bg-[#E74D4A] transition duration-200"
        onClick={() => {
            setIsModalOpen(false);
        }}
        >
        Okay
        </button>
    </div>
  </div>
)}


    </div>
    </div>
  );
}

export default BuildCoinsApproval;
