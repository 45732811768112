import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import MenuBar from './menuBar';
function WeightageForm() {
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [formData, setFormData] = useState({
    _id: '',
    LTV: '',
    invoiceCount: '',
    currentInvoiceValue: '',
    TOP: '',
    paymentConsistency: '',
    referralActivity: '',
    productDiversity: '',
    profitability: '',
    sustainablePractices: ''
  });

  useEffect(() => {
    fetchPartners();
  }, []);

  const fetchWeightage = async (partnerId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}partner/get-weightage-by-partner-id?partnerId=${partnerId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (data.data) {
        setFormData({
          _id: data.data._id,
          LTV: data.data.LTV || '',
          invoiceCount: data.data.invoiceCount || '',
          currentInvoiceValue: data.data.currentInvoiceValue || '',
          TOP: data.data.TOP || '',
          paymentConsistency: data.data.paymentConsistency || '',
          referralActivity: data.data.referralActivity || '',
          productDiversity: data.data.productDiversity || '',
          profitability: data.data.profitability || '',
          sustainablePractices: data.data.sustainablePractices || ''
        });
      }
    } catch (error) {
      console.error('Error fetching weightage:', error);
    }
  };

  const handlePartnerSelect = (selectedOption) => {
    setSelectedPartner(selectedOption);
    if (selectedOption) {
      fetchWeightage(selectedOption.value);
    } else {
      setFormData({
        _id: '',
        LTV: '',
        invoiceCount: '',
        currentInvoiceValue: '',
        TOP: '',
        paymentConsistency: '',
        referralActivity: '',
        productDiversity: '',
        profitability: '',
        sustainablePractices: ''
      });
    }
  };

  const partnerOptions = partners.map(partner => ({
    value: partner._id,
    label: partner.name
  }));

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#F85D5A' : '#E5E7EB',
      boxShadow: 'none',
      minHeight: '40px',
      '&:hover': {
        borderColor: '#F85D5A'
      }
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
      outline: 'none !important',
      border: 'none !important',
      boxShadow: 'none !important',
      '*': {
        outline: 'none !important',
        border: 'none !important',
        boxShadow: 'none !important'
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      outline: 'none',
      border: 'none',
      boxShadow: 'none'
    }),
    container: (provided) => ({
      ...provided,
      outline: 'none',
      border: 'none',
      boxShadow: 'none'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#F85D5A' : provided.backgroundColor,
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: '#E74D4A',
        color: 'white'
      }
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#D1D5DB', // Light gray color (you can adjust this hex code)
      fontSize: '1.1rem' // Optional: adjust font size if needed
    }),
  };

  const fetchPartners = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}partner/get-all-partners`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setPartners(data.data);
    } catch (error) {
      console.error('Error fetching partners:', error);
    }
  };

  const handleInputChange = (e) => {
    const value = Math.min(100, Math.max(0, Number(e.target.value)));
    setFormData({
      ...formData,
      [e.target.name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!selectedPartner) {
      alert('Please select a partner');
      return;
    }

    const submitData = {
      ...formData,
      partnerId: selectedPartner.value,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}partner/create-weightage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submitData)
      });

      const data = await response.json();

      if (response.ok) {
        setFormData({
          _id: '',
          LTV: '',
          invoiceCount: '',
          currentInvoiceValue: '',
          TOP: '',
          paymentConsistency: '',
          referralActivity: '',
          productDiversity: '',
          profitability: '',
          sustainablePractices: ''
        });
        setSelectedPartner(null);
        setShowSuccessPopup(true);
        setTimeout(() => setShowSuccessPopup(false), 3000);
      } else {
        alert(data.message || 'Submission failed');
      }
    } catch (error) {
      alert('Error submitting form');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center p-4 relative ">
            <div>
        <MenuBar/>
        </div> 
      {showSuccessPopup && (
        <div className="fixed top-4 right-4 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg animate-[fadeInOut_3s_ease-in-out]">
          Weightage saved successfully!
        </div>
      )}
      
      <div className="w-full mt-24 max-w-lg">
        <h2 className="text-2xl font-bold mb-6 text-[#F85D5A]">Partner Weightage</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="partner" className="block text-gray-700 font-medium mb-2">
              Select Partner
            </label>
            <Select
              id="partner"
              options={partnerOptions}
              value={selectedPartner}
              onChange={handlePartnerSelect}
              placeholder="Search partner..."
              styles={customStyles}
              isClearable
            />
          </div>

          {Object.keys(formData).filter(key => key !== '_id').map((field) => (
            <div key={field} className="mb-4">
              <label htmlFor={field} className="block text-gray-700 font-medium text-md mb-2">
                {field.replace(/([A-Z])/g, ' $1').trim()} (%)
              </label>
              <input
                type="number"
                id={field}
                name={field}
                value={formData[field]}
                onChange={handleInputChange}
                placeholder={`Enter ${field.replace(/([A-Z])/g, ' $1').trim()} percentage`}
                className="w-full border border-gray-300 rounded-md p-3 focus:ring-[#F85D5A] focus:border-[#F85D5A] text-[1.1rem] placeholder:text-[#D1D5DB]"
                min="0"
                max="100"
                required
              />
            </div>
          ))}

          <button
            type="submit"
            className="w-full bg-[#F85D5A] text-lg text-white font-semibold py-3 px-4 rounded-lg hover:bg-[#E74D4A] transition duration-200"
          >
            Save
          </button>
        </form>
      </div>
    </div>
  );
}

export default WeightageForm;
