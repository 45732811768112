import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function TransactionDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const transaction = location.state?.transaction;

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="bg-white shadow-sm">
        <div className="max-w-3xl mx-auto px-4 py-4">
          <div className="flex items-center">
            <button 
              onClick={() => navigate(-1)}
              className="p-2 rounded-full hover:bg-gray-100"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <h1 className="ml-4 text-xl font-semibold">Transaction Details</h1>
          </div>
        </div>
      </div>

      {/* Transaction Card */}
      <div className="max-w-3xl mx-auto px-4 py-6">
        <div className="bg-white rounded-2xl shadow-sm p-6">
          {/* Partner Info */}
          <div className="flex items-center mb-6">
            <div className="w-16 h-16 rounded-full bg-[#F85D5A] flex items-center justify-center">
              <span className="text-2xl font-bold text-white">
                {transaction?.partnerName.charAt(0)}
              </span>
            </div>
            <div className="ml-4">
              <h2 className="text-2xl font-bold">{transaction?.partnerName}</h2>
              <p className="text-gray-500">
                {new Date(transaction?.transactionDate).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </p>
            </div>
          </div>

          {/* Coins Amount */}
          <div className="border-t border-b border-gray-100 py-6 mb-6">
            <div className="text-center">
              <p className="text-gray-500 mb-2">BuildCoins</p>
              <div className={`text-4xl font-bold ${
                transaction?.transactionType === 'credit' ? 'text-green-600' : 'text-red-500'
              }`}>
                {transaction?.transactionType === 'credit' ? '+' : '-'}
                {transaction?.buildCoins} coins
              </div>
            </div>
          </div>

          {/* Invoice Details */}
          <div className="bg-gray-50 rounded-xl p-4 mb-6">
            <h3 className="text-lg font-semibold mb-4">Invoice Details</h3>
            <div className="space-y-3">
              <div className="flex justify-between">
                <span className="text-gray-600">Invoice Number</span>
                <span className="font-medium">{transaction?.invoiceId?.invoiceNumber || 'N/A'}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Invoice Date</span>
                <span className="font-medium">
                  {new Date(transaction?.invoiceDate || transaction?.invoiceId?.date).toLocaleDateString()}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Invoice Amount</span>
                <span className="font-medium">₹ {transaction?.invoiceId?.amount || '0.00'}</span>
              </div>
            </div>
          </div>

          {/* Transaction Details */}
          <div className="space-y-4">
{/*             <div className="flex justify-between">
              <span className="text-gray-500">Transaction ID</span>
              <span className="font-medium">{transaction?.id || 'N/A'}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-500">Status</span>
              <span className="px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
                {transaction?.status || 'Completed'}
              </span>
            </div> */}
{/*             <div className="flex justify-between">
              <span className="text-gray-500">Transaction Type</span>
              <span className="font-medium capitalize">{transaction?.transactionType}</span>
            </div> */}
            {transaction?.description && (
              <div className="pt-4 border-t border-gray-100">
                <h3 className="text-gray-500 mb-2">Description</h3>
                <p className="text-gray-800">{transaction?.description}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionDetails;
