import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuBar from './menuBar';
import Select from 'react-select';


function ClaimReward() {
  const [claiming, setClaiming] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [otpArray, setOtpArray] = useState(['', '', '', '', '']);
const inputRefs = useRef([]);
const [formData, setFormData] = useState({
  invoiceNumber: '',
  invoiceDate: '',
  amount: '',
  debitCoins: '',
 
});
const [consumers, setConsumers] = useState([]);
const [selectedConsumer, setSelectedConsumer] = useState(null);
const [showSuccessModal, setShowSuccessModal] = useState(false);


  // Existing URL params parsing
  const params = new URLSearchParams(location.search);
  const consumerId = params.get('consumerId');
  const partnerId = params.get('partnerId');
  const buildCoins = params.get('buildCoins');
  const coinLedgers = JSON.parse(params.get('coinLedgers'));
  const consumerName = params.get('consumerName');
  console.log(coinLedgers)

  if(params){
    sessionStorage.setItem('consumerId', consumerId);
    sessionStorage.setItem('partnerId', partnerId);
    sessionStorage.setItem('buildCoins', buildCoins);
    sessionStorage.setItem('coinLedgers', JSON.stringify(coinLedgers));
    sessionStorage.setItem('consumerName', consumerName);
  }


  const sendOtp = async () => {

    const invoiceAmount = parseFloat(formData.amount) || 0;
    const coinsToApply = parseFloat(formData.debitCoins) || 0;
    const availableCoins = parseFloat(buildCoins) || 0;

    if (coinsToApply > invoiceAmount) {
      setError('Applied coins cannot exceed invoice amount');
      return;
    }

    if (coinsToApply > availableCoins) {
      setError(`Cannot exceed available coins (${availableCoins})`);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}rewards/send-claim-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          consumerId: consumerId
        })
      });
  
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to send OTP');
      }
  
      if (data.success) {
        setShowOtpModal(true);
      }
    } catch (err) {
      setError(err.message);
    }
  };
  
  
  

  const verifyOtp = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}rewards/verify-claim-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          consumerId: consumerId,
          otp: otp
        })
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.message || 'Invalid OTP. Please try again.');
      }
  
      if (data.success) {
        await handleClaim();
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleClaim = async () => {
    setClaiming(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}partner/debit-consumer-rewards`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          consumerId: consumerId,
          partnerId: partnerId,
          debitCoins: parseInt(formData.debitCoins),
          coinLedgers: coinLedgers,
          invoiceNumber: formData.invoiceNumber,
          amount: formData.amount,
        })
      });

      const data = await response.json();
      console.log(data)
      if (!response.ok) {
        throw new Error('Failed to claim reward');
      }

      setSuccess(true);
      setShowOtpModal(false);
      setShowSuccessModal(true); // Show success modal

/*       sessionStorage.setItem('partnerId', partnerId);
        navigate('/give-coins', {
          state: {
            consumerId: consumerId,
            invoiceNumber: formData.invoiceNumber,
            amount: formData.amount,
            partnerId: partnerId,
          }
        }); */
      

    } catch (err) {
      setError(err.message);
    } finally {
      setClaiming(false);
    }
  };

  const handleNavigateToGiveCoins = () => {
    sessionStorage.setItem('partnerId', partnerId);
    navigate('/give-coins', {
      state: {
        consumerId: consumerId,
        invoiceNumber: formData.invoiceNumber,
        amount: formData.amount,
        partnerId: partnerId,
      }
    });
  };
  
  const handleNavigateToHome = () => {
    navigate('/buildcoins-approval');
  };

  // Add these handler functions
const handleOtpChange = (index, value) => {
  if (/^\d*$/.test(value)) {
    const newOtp = [...otpArray];
    newOtp[index] = value;
    setOtpArray(newOtp);
    setOtp(newOtp.join('')); // Update the original otp state
    if (value !== '' && index < 4) {
      inputRefs.current[index + 1].focus();
    }
  }
};

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && otpArray[index] === '') {
      inputRefs.current[index - 1].focus();
    }
  };

  const consumerOptions = consumers.map(consumer => ({
    value: consumer._id,
    label: consumer.name
  }));

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#F85D5A' : '#E5E7EB',
      boxShadow: 'none',
      minHeight: '40px',
      '&:hover': {
        borderColor: '#F85D5A'
      }
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
      outline: 'none !important',
      border: 'none !important',
      boxShadow: 'none !important',
      '*': {
        outline: 'none !important',
        border: 'none !important',
        boxShadow: 'none !important'
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      outline: 'none',
      border: 'none',
      boxShadow: 'none'
    }),
    container: (provided) => ({
      ...provided,
      outline: 'none',
      border: 'none',
      boxShadow: 'none'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#F85D5A' : provided.backgroundColor,
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: '#E74D4A',
        color: 'white'
      }
    })
  };

  useEffect(() => {
    fetchConsumers();
  }, []);
  

  // Add this useEffect after your existing useEffect
useEffect(() => {
  // Map consumer if consumerId exists in params
  if (consumerId) {
    const defaultConsumer = consumers.find(c => c._id === consumerId);
    if (defaultConsumer) {
      setSelectedConsumer({
        value: defaultConsumer._id,
        label: defaultConsumer.name
      });
    }
  }

  // Set today's date as default
  const today = new Date().toISOString().split('T')[0];
  setFormData(prev => ({
    ...prev,
    invoiceDate: today
  }));
}, [consumers, consumerId]);


  const fetchConsumers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}consumer/get-all-consumers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setConsumers(data.data);
    } catch (error) {
      console.error('Error fetching consumers:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'debitCoins') {
      const invoiceAmount = parseFloat(formData.amount) || 0;
      const coinsToApply = parseFloat(value) || 0;
      const availableCoins = parseFloat(buildCoins) || 0; // from URL params
      
      if (coinsToApply > invoiceAmount) {
        setError('Applied coins cannot exceed invoice amount');
        return;
      }
      
      if (coinsToApply > availableCoins) {
        setError(`Cannot exceed available coins (${availableCoins})`);
        return;
      }
      
      setError(null);
    }
  
    setFormData({
      ...formData,
      [name]: value
    });
  };
  


  return (
    <div className="pb-16">
    <div className="fixed bottom-0 left-0 right-0 bg-white">
      <MenuBar/>
    </div>
    <div className="h-screen overflow-y-auto flex items-center justify-center mt-10">
      <div className="bg-white p-8 rounded-lg border max-w-md w-full ">
        {!success ? (
          <>
<h1 className="text-2xl font-bold text-center mb-6">
  Apply cashback Coins to <span className="text-[#F85D5A]">{consumerName}</span>
</h1>
            <div className="mb-6 text-center">
              <p className="text-gray-600 mb-2">You are about to claim:</p>
              <p className="text-3xl font-bold text-[#F85D5A]">{buildCoins} Coins</p>
            </div>


            <div className="mb-4">
            <label htmlFor="customer" className="block text-gray-700 font-medium mb-1">
              Consumer
            </label>
             <Select
              id="customer"
              options={consumerOptions}
              value={selectedConsumer}
              onChange={setSelectedConsumer}
              placeholder="Search customer..."
              styles={customStyles}
              isClearable
            /> 
          </div>

           {/* Invoice Date */}
           <div className="mb-4">
            <label htmlFor="invoiceDate" className="block text-gray-700 font-medium mb-1">
              Invoice Date
            </label>
             <input
              type="date"
              id="invoiceDate"
              name="invoiceDate"
              value={formData.invoiceDate}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded-lg p-3 focus:ring-[#F85D5A] focus:border-[#F85D5A]"
              required
            /> 
          </div>

          {/* Invoice Number */}
            <div className="mb-4">
            <label htmlFor="invoiceNumber" className="block text-gray-700 font-medium mb-1">
              Invoice Number
            </label>
             <input
              type="text"
              id="invoiceNumber"
              name="invoiceNumber"
              value={formData.invoiceNumber}
              onChange={handleInputChange}
              placeholder="Enter invoice number"
              className="w-full border border-gray-300 rounded-lg p-3 focus:ring-[#F85D5A] focus:border-[#F85D5A]"
              required
            />
          </div>

          {/* Invoice Amount */}
          <div className="mb-4">
            <label htmlFor="invoiceNumber" className="block text-gray-700 font-medium mb-1">
              Invoice Amount
            </label>
             <input
              type="text"
              id="amount"
              name="amount"
              value={formData.amount}
              onChange={handleInputChange}
              placeholder="Enter invoice amount"
              className="w-full border border-gray-300 rounded-lg p-3 focus:ring-[#F85D5A] focus:border-[#F85D5A]"
              required
            />
          </div>

          <div className="mb-4">
          <label htmlFor="debitCoins" className="block text-gray-700 font-medium mb-1">
            Apply Coins (Available: {buildCoins})
          </label>
          <input
            type="number"
            id="debitCoins"
            name="debitCoins"
            value={formData.debitCoins}
            onChange={handleInputChange}
            placeholder="Enter Coins to apply"
            className="w-full border border-gray-300 rounded-lg p-3 focus:ring-[#F85D5A] focus:border-[#F85D5A]"
            required
          />
          {/* {error && <p className="text-red-500 text-sm mt-1">{error}</p>} */}
        </div>


            <button
              onClick={sendOtp} // Changed from handleClaim to sendOtp
              disabled={claiming}
              className="w-full bg-[#F85D5A] text-white py-3 rounded-lg font-bold hover:bg-[#E74D4A] transition duration-200 disabled:opacity-50"
            >
              {claiming ? 'Claiming...' : 'Apply'}
            </button>
            {error && (
              <p className="mt-4 text-red-500 text-center">{error}</p>
            )}
          </>
        ) : (
          <div className="text-center">
            <div className="mb-4">
              <svg className="w-16 h-16 text-green-500 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
            </div>
            <h2 className="text-2xl font-bold text-green-600 mb-2">Coins Claimed Successfully!</h2>
          </div>
        )}
      </div>

      {showOtpModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg">
      <h2 className="text-xl font-bold mb-4">Enter OTP</h2>
      <div className="flex justify-center items-center space-x-2 mb-4">
        {otpArray.map((value, index) => (
          <input
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength="1"
            value={value}
            onChange={(e) => handleOtpChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            className="w-12 h-12 text-center border border-gray-300 rounded-md"
          />
        ))}
      </div>
      <button
        onClick={verifyOtp}
        className="w-full bg-[#F85D5A] text-white py-2 rounded font-bold"
      >
        Verify OTP
      </button>
    </div>
  </div>
)}
    </div>

    {showSuccessModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full mx-4">
      <div className="text-center">
        <div className="mb-4">
          <svg className="w-16 h-16 text-green-500 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
          </svg>
        </div>
        <h2 className="text-2xl font-bold text-green-600 mb-4">Coins Applied Successfully!</h2>
        <p className="text-gray-600 mb-6">Would you like to give more coins or return to home?</p>
        <div className="space-y-3">
          <button
            onClick={handleNavigateToGiveCoins}
            className="w-full bg-[#F85D5A] text-white py-3 rounded-lg font-bold hover:bg-[#E74D4A] transition duration-200"
          >
            Give More Coins
          </button>
          <button
            onClick={handleNavigateToHome}
            className="w-full border border-[#F85D5A] text-[#F85D5A] py-3 rounded-lg font-bold hover:bg-gray-50 transition duration-200"
          >
            Skip to Home
          </button>
        </div>
      </div>
    </div>
  </div>
)}



    </div>
  );
}


export default ClaimReward;
