import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './consumer/ConsumerHome';
import ClaimReward from './partner/ClaimReward';
import UploadInvoice from './consumer/UploadInvoice';
import InvoiceForm from './partner/InvoiceForm';
import WeightageForm from './partner/WeightageForm';
import ParametersForm from './partner/ParametersForm';
import ConsumerLogin from './consumer/ConsumerLogin';
import OTPpage from './consumer/OTPpage';
import Redeem from './consumer/Redeem';
import BuildCoinsApproval from './partner/BuildCoinsApproval';
import UserLogin from './partner/UserLogin';
import GiveCoinsForm from './partner/GiveCoins';
import TransactionDetails from './consumer/TransactionDetails';
import ScanQR from './partner/scanQR'
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/consumer" element={<ConsumerLogin />} />
        <Route path="/consumer/otp" element={<OTPpage />} />
        <Route path="/invoice-form" element={<InvoiceForm />} />
        <Route path="/weightage-form" element={<WeightageForm />} />
        <Route path="/parameters-form" element={<ParametersForm />} />
        <Route path='/consumer/home' element={<Home />} />
        <Route path="/consumer/claim-reward" element={<ClaimReward />} />
        <Route path='/consumer/redeem' element = {<Redeem />} />
        <Route path="/consumer/upload-invoice" element={<UploadInvoice />} />
        <Route path="/buildcoins-approval" element={<BuildCoinsApproval />} />
        <Route path="/login" element={<UserLogin />} />
        <Route path="/give-coins" element={<GiveCoinsForm />} />
        <Route path="/consumer/transaction-details" element={<TransactionDetails />} />
        <Route path="/scan/qr" element={<ScanQR/>}/>
      </Routes>
    </Router>
  );
}
export default App;