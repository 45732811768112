/* import React, { useState,useRef, useEffect } from 'react';
import { CIcon } from '@coreui/icons-react';
import { 
  cilHome,
  cilWallet,
  cilQrCode,
  cilGift,
  cilUser,
  cilMenu
} from '@coreui/icons';
import { useNavigate } from 'react-router-dom';

const MenuBar = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleInvoice = () => {
    navigate('/invoice-form');
    setIsMenuOpen(false);
  };

  const handleWeightage = () => {
    navigate('/weightage-form');
    setIsMenuOpen(false);
  };

  const handleScan = () => {
    navigate('/buildcoins-approval');
    setIsMenuOpen(false);
  };

  const handleCoins = () => {
    navigate('/give-coins');
    setIsMenuOpen(false);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate('/user/login');
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="fixed top-0 left-0 right-0 bg-white shadow-lg z-50" ref={menuRef}>
      <div className="flex justify-between items-center py-2 px-4">
        <div className="text-xl font-bold"><img src={'/BB.png'} alt="Logo" className='h-16 w-16 sm:w-14 sm:h-14'/></div>
        <button onClick={toggleMenu} className="p-2">
          <CIcon icon={cilMenu} className="w-6 h-6 text-gray-600" />
        </button>
      </div>

      {isMenuOpen && (
        <div className="absolute top-full right-0 bg-white shadow-lg w-64 py-2 rounded-bl-lg">
          <button onClick={handleInvoice} className="w-full flex items-center gap-3 px-4 py-2 hover:bg-gray-100">
            <CIcon icon={cilHome} className="w-5 h-5 text-gray-600" />
            <span className="text-sm font-medium text-gray-600">Invoice Form</span>
          </button>

          <button onClick={handleWeightage} className="w-full flex items-center gap-3 px-4 py-2 hover:bg-gray-100">
            <CIcon icon={cilWallet} className="w-5 h-5 text-gray-600" />
            <span className="text-sm font-medium text-gray-600">Weightage Form</span>
          </button>

          <button onClick={handleScan} className="w-full flex items-center gap-3 px-4 py-2 hover:bg-gray-100">
            <CIcon icon={cilQrCode} className="w-5 h-5 text-gray-600" />
            <span className="text-sm font-medium text-gray-600">BuildCoins Approval</span>
          </button>

        <button onClick={handleCoins} className="w-full flex items-center gap-3 px-4 py-2 hover:bg-gray-100">
            <CIcon icon={cilGift} className="w-5 h-5 text-gray-600" />
            <span className="text-sm font-medium text-gray-600">Give Coins</span>
          </button>

            <button onClick={handleLogout} className="w-full flex items-center gap-3 px-4 py-2 hover:bg-gray-100">
            <CIcon icon={cilUser} className="w-5 h-5 text-gray-600" />
            <span className="text-sm font-medium text-gray-600">Logout</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default MenuBar; */



import React from 'react';
import { CIcon } from '@coreui/icons-react';
import { 
  cilHome,
  cilShortText,
  cilQrCode,
  cilGift,
  cilUser
} from '@coreui/icons';
import { useNavigate } from 'react-router-dom';

const MenuBar = () => {
  const navigate = useNavigate();

  const handleHome = () => {
    navigate('/buildcoins-approval');
  };

  const handleWallet = () => {
    navigate('/invoice-form');
  };

  const handleScan = () => {
    navigate('/scan/qr');
  };

  const handleRewards = () => {
    navigate('/give-coins');
  };

  const handleProfile = () => {
    navigate('/weightage-form');
  };



  return (
    <div className="fixed bottom-0 left-0 right-0 flex justify-between items-center border-t border-gray-200 py-2 px-4 bg-white shadow-lg">
      <button onClick={handleHome} className="flex flex-col items-center gap-1">
        <CIcon icon={cilHome} className="w-6 h-6 text-gray-600" />
        <span className="text-xs font-medium text-gray-600">Home</span>
      </button>

      <button onClick={handleWallet} className="flex flex-col items-center gap-1">
        <CIcon icon={cilShortText} className="w-6 h-6 text-gray-600" />
        <span className="text-xs font-medium text-gray-600">Invoice</span>
      </button>

      <button onClick={handleScan} className="flex flex-col items-center gap-1">
        <div className="bg-[#F85D5A] p-3 rounded-full -mt-5">
          <CIcon icon={cilQrCode} className="w-6 h-6 text-white" />
        </div>
        <span className="text-xs font-medium text-gray-600">Scan</span>
      </button>

      <button onClick={handleRewards} className="flex flex-col items-center gap-1">
        <CIcon icon={cilGift} className="w-6 h-6 text-gray-600" />
        <span className="text-xs font-medium text-gray-600">Coins</span>
      </button>

      <button onClick={handleProfile} className="flex flex-col items-center gap-1">
        <CIcon icon={cilUser} className="w-6 h-6 text-gray-600" />
        <span className="text-xs font-medium text-gray-600">Profile</span>
      </button>
    </div>
  );
};

export default MenuBar;
