import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import MenuBar from './menuBar';
import { useNavigate } from 'react-router-dom';

function InvoiceForm() {
  const navigate = useNavigate();

  const [partners, setPartners] = useState([]);
  const [consumers, setConsumers] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedConsumer, setSelectedConsumer] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [formData, setFormData] = useState({
    invoiceNumber: '',
    invoiceDate: '',
    amount: '',
  });

  useEffect(() => {
    fetchPartners();
    fetchConsumers();
  }, []);

  useEffect(() => {
    const storedPartnerId = sessionStorage.getItem('userId');
    if (storedPartnerId && partners.length > 0) {
      const matchingPartner = partners.find(partner => partner._id === storedPartnerId);
      if (matchingPartner) {
        setSelectedPartner({
          value: matchingPartner._id,
          label: matchingPartner.name
        });
      }
    }
  }, [partners]);
  

  const partnerOptions = partners.map(partner => ({
    value: partner._id,
    label: partner.name
  }));

  const consumerOptions = consumers.map(consumer => ({
    value: consumer._id,
    label: consumer.name
  }));

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#F85D5A' : '#E5E7EB',
      boxShadow: 'none',
      minHeight: '40px',
      '&:hover': {
        borderColor: '#F85D5A'
      }
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
      outline: 'none !important',
      border: 'none !important',
      boxShadow: 'none !important',
      '*': {
        outline: 'none !important',
        border: 'none !important',
        boxShadow: 'none !important'
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      outline: 'none',
      border: 'none',
      boxShadow: 'none'
    }),
    container: (provided) => ({
      ...provided,
      outline: 'none',
      border: 'none',
      boxShadow: 'none'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#F85D5A' : provided.backgroundColor,
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: '#E74D4A',
        color: 'white'
      }
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#D1D5DB', // Light gray color (you can adjust this hex code)
      fontSize: '1.1rem' // Optional: adjust font size if needed
    }),
  };

  const fetchPartners = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}partner/get-all-partners`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setPartners(data.data);
    } catch (error) {
      console.error('Error fetching partners:', error);
    }
  };

  const fetchConsumers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}consumer/get-all-consumers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setConsumers(data.data);
    } catch (error) {
      console.error('Error fetching consumers:', error);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!selectedConsumer || !selectedPartner) {
      alert('Please select both customer and partner');
      return;
    }

    const submitData = {
      ...formData,
      consumerId: selectedConsumer.value,
      partnerId: selectedPartner.value,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}partner/create-invoice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submitData)
      });

      const data = await response.json();
      console.log("response:",data)

      if (response.ok) {
        setFormData({
          invoiceNumber: '',
          invoiceDate: '',
          amount: '',
        });
        setSelectedConsumer(null);
        setSelectedPartner(null);
        setShowSuccessPopup(true);
        setTimeout(() => setShowSuccessPopup(false), 3000);
        setTimeout(() => {
          setShowSuccessPopup(false);
          navigate('/buildcoins-approval');
        }, 2000);
      } else {
        alert(data.message || 'Submission failed');
      }
    } catch (error) {
      console.log('Error submitting form:', error);
      alert('Error submitting form');
    }
  };

  return (
    
    <div className="min-h-screen flex items-center justify-center p-2 relative">
      <div>
        <MenuBar/>
        </div> 
      {showSuccessPopup && (
        <div className="fixed top-4 right-4 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg animate-[fadeInOut_3s_ease-in-out]">
          Invoice created successfully!
        </div>
      )}
      
      <div className="w-full max-w-lg ">
        <h2 className="text-2xl font-bold mb-6 text-[#F85D5A]">Invoice Details</h2>
        <form onSubmit={handleSubmit}>
          {/* Customer Selection */}
          <div className="mb-4">
            <label htmlFor="customer" className="block text-gray-700 font-medium text-md mb-2">
              Select Customer
            </label>
            <Select
              id="customer"
              options={consumerOptions}
              value={selectedConsumer}
              onChange={setSelectedConsumer}
              placeholder="Search customer..."
              styles={customStyles}
              isClearable
            />
          </div>

          {/* Partner Selection */}
          <div className="mb-4">
            <label htmlFor="partner" className="block text-gray-700 font-medium text-md mb-2">
              Select Partner
            </label>
            <Select
              id="partner"
              options={partnerOptions}
              value={selectedPartner}
              onChange={setSelectedPartner}
              placeholder="Search partner..."
              styles={customStyles}
              isClearable
            />
          </div>

          {/* Invoice Number */}
          <div className="mb-4">
            <label htmlFor="invoiceNumber" className="block text-gray-700 font-medium text-md mb-2">
              Invoice Number
            </label>
            <input
              type="text"
              id="invoiceNumber"
              name="invoiceNumber"
              value={formData.invoiceNumber}
              onChange={handleInputChange}
              placeholder="Enter invoice number"
              className="w-full border border-gray-300 rounded-lg p-3 focus:ring-[#F85D5A] focus:border-[#F85D5A] text-[1.1rem] placeholder:text-[#D1D5DB]"
              required
            />
          </div>

          {/* Invoice Date */}
          <div className="mb-4">
            <label htmlFor="invoiceDate" className="block text-gray-700 font-medium text-md mb-2">
              Invoice Date
            </label>
            <input
              type="date"
              id="invoiceDate"
              name="invoiceDate"
              value={formData.invoiceDate}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded-lg p-3 focus:ring-[#F85D5A] focus:border-[#F85D5A] text-[1.1rem] placeholder:text-[#D1D5DB]"
              required
            />
          </div>

          {/* Amount */}
          <div className="mb-4">
            <label htmlFor="amount" className="block text-gray-700 font-medium text-md mb-2">
              Amount
            </label>
            <input
              type="number"
              id="amount"
              name="amount"
              value={formData.amount}
              onChange={handleInputChange}
              placeholder="Enter amount"
              min="0"
              step="0.01"
              className="w-full border border-gray-300 rounded-lg p-3 focus:ring-[#F85D5A] focus:border-[#F85D5A] text-[1.1rem] placeholder:text-[#D1D5DB]"
              required
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full text-lg bg-[#F85D5A] text-white font-semibold py-3 px-4 rounded-lg hover:bg-[#E74D4A] transition duration-200"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default InvoiceForm;
