import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuBar from './menuBar';
import { FaSignal, FaWifi, FaBatteryFull, FaUserCircle, FaBell, FaMoneyBillWave, FaWallet, FaQrcode, FaCreditCard, FaPiggyBank, FaGift, FaHistory, FaHandPointer } from "react-icons/fa";
import QrScanner from 'react-qr-scanner';

function ScanQR() {
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file); // Store the actual file instead of URL
    }
  };
  
  const handleUploadInvoice = async () => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('image', selectedImage);
  
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}rewards/add-customer-invoice`, {
        method: 'POST',
        body: formData
      });
  
      const data = await response.json();
      console.log(data);
      if (data) {
        setIsModalOpen(true);
      }
    } catch (err) {
      setError('Failed to upload invoice');
    } finally {
      setIsUploading(false);
    }
  };
  
  const handleCameraOpen = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.capture = 'environment'; // This forces the camera to open directly
    input.className = 'hidden';
    
    input.onchange = (event) => {
      handleFileUpload(event);
    };
  
    input.click();
  };


  const openNativeCamera = () => {
    // Create a temporary input element
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    // This tells mobile devices to use their native camera app
    input.capture = 'camera';
    
    input.onchange = (event) => {
      handleFileUpload(event);
    };
  
    // Trigger the native camera
    input.click();
  };

    const handleScan = (data) => {
      if (data) {
        console.log('QR Code detected:', data);
        // Handle QR code data here
      }
    };
  
  return (
<div className="fixed inset-0 bg-gradient-to-br from-[#F85D5A] via-[#E54542] to-[#D12D2A] overflow-hidden">


      <div className="mt-20 w-full max-w-xl">
        <MenuBar />
      </div>
    </div>
  );
}

export default ScanQR;
