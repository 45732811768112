import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Redeem() {
  const navigate = useNavigate();

  const [showQR, setShowQR] = useState(false);
  const [qrLoading, setQrLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const consumerId = sessionStorage.getItem('consumerId');
  const consumer = sessionStorage.getItem('userData');
  const consumerName = JSON.parse(consumer).name;
  const [selectedPartner, setSelectedPartner] = useState(null);
console.log(selectedPartner)
  const location = useLocation();
  const eligibility = location.state?.eligibility || [];
  const pendingCoins = location.state?.pendingCoins || 0;
  const lifetimeCoins = location.state?.lifetimeCoins || 0;
  const redeemedCoins = location.state?.redeemedCoins || 0;
  const availableCoins = location.state?.availableCoins || 0;
console.log(eligibility)
console.log(pendingCoins)
console.log(lifetimeCoins)
console.log(redeemedCoins)

  const getSequentialColor = (index) => {
    const colors = ['bg-blue-500', 'bg-green-500', 'bg-purple-500'];
    return colors[index % colors.length];
  };
  

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  return (
    <div className="h-full ">
      <div className="fixed top-0 left-0 right-0 bg-white mt-8 ">
      <div className="max-w-sm flex items-center pl-3">
    <button 
      onClick={() => navigate(-1)} 
      className="text-gray-600 flex items-center justify-center"
    >
      <FontAwesomeIcon icon={faChevronLeft} className='w-6 h-6' />
    </button>
    <h2 className="text-3xl font-bold">Redeem</h2>
  </div>

  {/* <div className="w-full flex justify-center items-center mt-4 ">
  <img 
    src={`${process.env.PUBLIC_URL}/redeem4.avif`} 
    alt="Logo" 
    className="h-40 w-auto object-contain max-w-sm" 
  />
</div> */}

        
<div className="pl-4 pr-4 mt-8">
    {eligibility.length === 0 && availableCoins === 0 ? (
      <div className="min-h-[160px] flex items-center justify-center border border-[#F85D5A] rounded-lg">
        <div className="text-center text-lg font-bold text-gray-600">
          {pendingCoins > 0 ? (
            <span>You have <span className="text-green-600 text-2xl font-extrabold">{pendingCoins}</span> coins pending approval. Please wait for partner confirmation.</span>
          ) : lifetimeCoins === 0 ? (
            "Start earning coins by making purchases from our partner stores!"
          ) : redeemedCoins === lifetimeCoins ? (
            "You've redeemed all your coins! Visit our partners to earn more."
          ) : (
            "No approved coins available. Contact the partner for approval."
          )}
        </div>
      </div>
    ) : (
              eligibility.map((item, index) => (
                <div 
                  key={index} 
                  className="pb-4 cursor-pointer hover:bg-gray-50 rounded-lg p-2"
                  onClick={() => handleItemClick(item)}
                >
                        <div className="flex items-center mb-2">
                        <div className={`${getSequentialColor(index)} w-10 h-10 rounded-lg flex items-center justify-center text-white font-bold mr-3`}>
                          {item.partnerName.charAt(0)}
                        </div>

              <div className="flex-1">
                <div className="flex justify-between items-center space-x-2">
                  <span className="font-medium text-xl">{item.partnerName}</span>
                  <span className="font-bold text-green-600 text-xl whitespace-nowrap">{item.totalCoins} coins</span>
                </div>
              </div>

              </div>
              </div>
              ))
            )}
          </div>
                </div>

      {/* Modal */}
      {isModalOpen && (
  <div className="fixed z-40 inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full relative">
      <button
        onClick={() => setIsModalOpen(false)}
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>

      <h2 className="text-xl font-bold mb-4">Select to Redeem</h2>
    
    <div className="mb-4">
      {selectedItem?.combinedEligiblePartners.map((partner, index) => (
        <div key={index} className="flex items-center space-x-3 mb-2">
          <input
            type="radio"
            id={`partner-${index}`}
            name="partner-selection"
            className="h-4 w-4 text-[#F85D5A]"
            onChange={() => setSelectedPartner(partner)}
          />
          <div className="flex-1">
            <div className="flex justify-between items-center">
              <span className="font-medium text-lg">{partner.partnerName}</span>
              <span className="font-bold text-green-600">{partner.totalCoins} coins</span>
            </div>
          </div>
        </div>
      ))}
    </div>


      <button
        className="w-full text-lg bg-[#F85D5A] text-white font-bold py-3 rounded-lg hover:bg-[#E74D4A] transition duration-200"
        onClick={() => setShowQR(true)}
      >
        Redeem
      </button>
    </div>
  </div>
)}



      {/* QR Code Modal */}
      {showQR && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg relative">
            <button
              onClick={() => {
                setShowQR(false);
                setIsModalOpen(false);
                navigate('/consumer/home');
              }}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className="p-4">
              {qrLoading && (
                <div className="flex items-center justify-center">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#F85D5A]" />
                </div>
              )}
            <img 
              src={`http://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodeURIComponent(
                `https://buildcoins.in/consumer/claim-reward?consumerId=${consumerId}&partnerId=${selectedPartner.partnerId}&buildCoins=${selectedPartner.totalCoins}&coinLedgers=${JSON.stringify(selectedPartner.individualTransactions)}&consumerName=${consumerName}`
              )}`}
              alt="QR Code"
              onLoad={() => setQrLoading(false)}
              className={qrLoading ? 'hidden' : 'block'}
            />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Redeem;
