import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import MenuBar from './menuBar';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


function GiveCoinsForm() {
  const navigate = useNavigate();

  const [partners, setPartners] = useState([]);
  const [consumers, setConsumers] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedConsumer, setSelectedConsumer] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [formData, setFormData] = useState({
    invoiceNumber: '',
    amount: '',
    buildCoins: '',
  });
  const partner = sessionStorage.getItem('userId');
  const location = useLocation();
  const { consumerId, invoiceNumber,amount,partnerId } = location.state || {};
  console.log('State being passed:', {consumerId, invoiceNumber,amount});


  useEffect(() => {
    fetchPartners();
    fetchConsumers();
  }, []);


  useEffect(() => {
    if (consumerId) {
      // Find and set the consumer
      const defaultConsumer = consumers.find(c => c._id === consumerId);
      if (defaultConsumer) {
        setSelectedConsumer({
          value: defaultConsumer._id,
          label: defaultConsumer.name
        });
      }
    }
  
    if (invoiceNumber) {
      // Set the invoice number in form data
      setFormData(prev => ({
        ...prev,
        invoiceNumber: invoiceNumber
      }));
    }

    if (amount) {
      // Set the invoice number in form data
      setFormData(prev => ({
        ...prev,
        amount: amount
      }));
    }

  }, [consumers, consumerId, invoiceNumber]);
  

  const partnerOptions = partners.map(partner => ({
    value: partner._id,
    label: partner.name
  }));

  const consumerOptions = consumers.map(consumer => ({
    value: consumer._id,
    label: consumer.name
  }));

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#F85D5A' : '#E5E7EB',
      boxShadow: 'none',
      minHeight: '40px',
      '&:hover': {
        borderColor: '#F85D5A'
      }
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
      outline: 'none !important',
      border: 'none !important',
      boxShadow: 'none !important',
      '*': {
        outline: 'none !important',
        border: 'none !important',
        boxShadow: 'none !important'
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      outline: 'none',
      border: 'none',
      boxShadow: 'none'
    }),
    container: (provided) => ({
      ...provided,
      outline: 'none',
      border: 'none',
      boxShadow: 'none'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#F85D5A' : provided.backgroundColor,
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: '#E74D4A',
        color: 'white'
      }
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#D1D5DB', // Light gray color (you can adjust this hex code)
      fontSize: '1.1rem' // Optional: adjust font size if needed
    }),
  };

  const fetchPartners = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}partner/get-all-partners`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setPartners(data.data);
    } catch (error) {
      console.error('Error fetching partners:', error);
    }
  };

  const fetchConsumers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}consumer/get-all-consumers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setConsumers(data.data);
    } catch (error) {
      console.error('Error fetching consumers:', error);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!selectedConsumer) {
      alert('Please select the customer');
      return;
    }

    const submitData = {
      ...formData,
      consumerId: selectedConsumer.value,
      partnerId: partnerId || partner,
      amount:amount || formData.amount,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}rewards/create-coin-transaction`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submitData)
      });

      const data = await response.json();
      console.log("response:",data)

      if (response.ok) {
        setFormData({
          invoiceNumber: '',
          buildCoins: '',
          amount: '',
        });
        setSelectedConsumer(null);
        setSelectedPartner(null);
        setShowSuccessPopup(true);
        setTimeout(() => {
          setShowSuccessPopup(false);
        navigate('/buildcoins-approval');
      }, 2000);
      } else {
        alert(data.message || 'Submission failed');
      }
    } catch (error) {
      console.log('Error submitting form:', error);
      alert('Error submitting form');
    }
  };

  return (
    <div>

    <div className="min-h-screen flex items-center justify-center p-2 relative">
    <div>
        <MenuBar/>
        </div> 
      {showSuccessPopup && (
        <div className="fixed top-4 right-4 mt-20 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg animate-[fadeInOut_3s_ease-in-out]">
          Coins credited successfully!
        </div>
      )}
      
      <div className="w-full max-w-lg ">
        <h2 className="text-2xl font-bold mb-6 text-[#F85D5A]">Give Coins</h2>
        <form onSubmit={handleSubmit}>
          {/* Customer Selection */}
          <div className="mb-4">
            <label htmlFor="customer" className="block text-gray-700 font-medium text-md mb-2">
              Select Customer
            </label>
            <Select
              id="customer"
              options={consumerOptions}
              value={selectedConsumer}
              onChange={setSelectedConsumer}
              placeholder="Search customer..."
              styles={customStyles}
              isClearable
            />
          </div>

          {/* Invoice Number */}
          <div className="mb-4">
            <label htmlFor="invoiceNumber" className="block text-gray-700 font-medium text-md mb-2">
              Invoice Number
            </label>
            <input
              type="text"
              id="invoiceNumber"
              name="invoiceNumber"
              value={formData.invoiceNumber}
              onChange={handleInputChange}
              placeholder="Enter invoice number"
              className="w-full border border-gray-300 rounded-lg p-3 focus:ring-[#F85D5A] focus:border-[#F85D5A] text-[1.1rem] placeholder:text-[#D1D5DB]"
              required
            />
          </div>


                    {/* Invoice Amount */}
                    <div className="mb-4">
            <label htmlFor="amount" className="block text-gray-700 font-medium text-md mb-2">
              Invoice Amount
            </label>
            <input
              type="text"
              id="amount"
              name="amount"
              value={formData.amount}
              onChange={handleInputChange}
              placeholder="Enter invoice amount"
              className="w-full border border-gray-300 rounded-lg p-3 focus:ring-[#F85D5A] focus:border-[#F85D5A] text-[1.1rem] placeholder:text-[#D1D5DB]"
              required
            />
          </div>

          {/* Coins */}
          <div className="mb-4">
            <label htmlFor="buidCoins" className="block text-gray-700 font-medium text-md mb-2">
              Coins
            </label>
            <input
              type="number"
              id="buildCoins"
              name="buildCoins"
              value={formData.buildCoins}
              onChange={handleInputChange}
              placeholder="Enter coins"
              min="0"
              step="0.01"
              className="w-full border border-gray-300 rounded-lg p-3 focus:ring-[#F85D5A] focus:border-[#F85D5A] text-[1.1rem] placeholder:text-[#D1D5DB]"
              required
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-[#F85D5A] text-white text-lg font-semibold py-3 px-4 rounded-lg hover:bg-[#E74D4A] transition duration-200"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
    </div>
  );
}

export default GiveCoinsForm;
