import React, { useState, useEffect } from "react";
import Menu from './menu';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();
  const [rewardsData, setRewardsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [transactions, setTransactions] = useState([]);
  const [eligibility, setEligibility] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showQR, setShowQR] = useState(false);
  const [qrLoading, setQrLoading] = useState(true);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [showLifetimeInfoPopup, setShowLifetimeInfoPopup] = useState(false);
  const [showRedeemedInfoPopup, setShowRedeemedInfoPopup] = useState(false);
  const [showRedeemableInfoPopup, setShowRedeemableInfoPopup] = useState(false);
  const [pendingCoins,setPendingCoins] = useState(0);
  const [redeemedCoins,setRedeemedCoins] = useState(0);
  const [lifetimeCoins,setLifetimeCoins] = useState(0);
  const [availableCoins,setAvailableCoins] = useState(0);
console.log(selectedItem)
  useEffect(() => {
    fetchRewards();
  }, []);

  const consumerId = sessionStorage.getItem('consumerId');
  const consumer = sessionStorage.getItem('userData');
  const consumerName = JSON.parse(consumer).name;
  console.log(consumerName)
  const fetchRewards = async () => {
    try {
      const data = await getConsumerRewards(consumerId);
      console.log(data.data)
      setRewardsData(data.data);
      // Sort transactions by date before setting state
      const sortedTransactions = [...(data.data.rewards || [])].sort((a, b) => 
        new Date(b.transactionDate) - new Date(a.transactionDate)
      );
      setTransactions(sortedTransactions);
      console.log("eligibillity array:",data.data.eligibility)
      setEligibility(data.data.eligibility || []);
      setPendingCoins(data.data.totalPendingCoins);
      setLifetimeCoins(data.data.totalBuildCoins);
      setRedeemedCoins(data.data.totalDebitCoins);
      setAvailableCoins(data.data.totalAvailableCoins);
      setError(null);
    } catch (error) {
      setError(error.message);
      setRewardsData(null);
    } finally {
      setLoading(false);
    }
  };
  

  const getConsumerRewards = async (consumerId) => {
    try {
      if (!consumerId) {
        throw new Error("Consumer ID is required");
      }

      const apiUrl = `${process.env.REACT_APP_BUILDCOINS}rewards/get-consumer-rewards?consumer_id=${consumerId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch consumer rewards");
      }

      const data = await response.json();
      console.log("Response data:", data);
      return data;
    } catch (error) {
      console.error("Error fetching consumer rewards:", error.message);
      throw error;
    }
  };

  const handleClaimCashback = () => {
   // setIsModalOpen(true); // Open the modal
   navigate('/consumer/redeem', { state: { eligibility ,pendingCoins,redeemedCoins,lifetimeCoins,availableCoins} }); // Navigate to redeem page instead of opening modal
   console.log(eligibility)
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  if (loading) {
    return (
      <div className="bg-gray-100 min-h-screen flex items-center justify-center">
        <p>Loading...</p>
      </div>
    );
  }

/*   if (error) {
    return (
      <div className="bg-gray-100 min-h-screen flex items-center justify-center">
        <p className="text-red-500">{error}</p>
      </div>
    );
  } */

  const getSequentialColor = (index) => {
    const colors = ['bg-blue-500', 'bg-green-500', 'bg-purple-500'];
    return colors[index % colors.length];
  };


  return (
<div className="min-h-screen flex flex-col">
<div className="w-full px-6 mt-6">
<h1 className="text-3xl font-bold mb-4">BuildCoins</h1>
      </div>
      <div className="flex-1 flex flex-col items-center justify-center p-4">

<div className="bg-gradient-to-br from-[#F85D5A] via-[#E54542] to-[#D12D2A] rounded-lg p-8 shadow-md w-full max-w-md">
       
        <div className="flex items-center justify-between mb-4">
        <div className="text-white text-2xl font-bold">
  <div className="bg-white p-2 rounded-full w-12 h-12 flex items-center justify-center">
    <img src={`${process.env.PUBLIC_URL}/BB.png`} alt="Logo" className="h-8 w-auto" />
  </div>
</div>

          <div className="flex items-center">
          <span className="text-gray-300 text-sm mr-2">
            {new Date().toLocaleDateString()}
          </span>
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-300"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg> */}
          </div>
        </div>
        <div className="text-center text-white text-5xl font-bold">
          {rewardsData?.totalBuildCoins || 0}
        </div>
        <div className="flex items-center justify-center gap-1 text-white text-lg font-medium mt-2">
  Lifetime Coins
  <button
      onClick={() => setShowLifetimeInfoPopup(true)}
      className="flex items-center justify-center text-sm -ml-4" 
    >
  <div className="w-6 h-6 rounded-full bg-[#F85D5A] bg-opacity-10 flex items-center justify-center">
    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  </div>
  </button>
</div>

        <div className="mt-6">
        <div className="flex items-center justify-between text-white text-xl font-medium mb-2">
  <div className="flex items-center gap-0">
    <span>Redeemed</span>
    <button
      onClick={() => setShowRedeemedInfoPopup(true)}
      className="flex items-center justify-center text-sm -ml-4"
    >
      <div className="w-10 h-10 rounded-full bg-[#F85D5A] bg-opacity-10 flex items-center justify-center">
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
    </button>
  </div>
  <span className="text-xl">{rewardsData?.totalDebitCoins || 0}</span>
</div>

<div className="flex items-center justify-between text-white text-xl font-medium mb-2">
  <div className="flex items-center gap-0">
    <span>On the way</span>
    <button
      onClick={() => setShowInfoPopup(true)}
      className="flex items-center justify-center text-sm -ml-4" 
    >
      <div className="w-10 h-10 rounded-full bg-[#F85D5A] bg-opacity-10 flex items-center justify-center">
        <svg className="w-5 h-5 flex justify-center items-center" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
    </button>
  </div>
  <span>{rewardsData?.totalPendingCoins || 0}</span>
</div>
<div className="flex items-center justify-between text-white text-xl font-medium mb-2">
  <div className="flex items-center gap-0">
    <span>Redeemable</span>
    <button
      onClick={() => setShowRedeemableInfoPopup(true)}
      className="flex items-center justify-center text-sm -ml-4"
    >
      <div className="w-10 h-10 rounded-full bg-[#F85D5A] bg-opacity-10 flex items-center justify-center">
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
    </button>
  </div>
  <span>{rewardsData?.totalAvailableCoins || 0}</span>
</div>

        </div>
        <button
  className="mt-4 w-full text-lg bg-white/20 backdrop-blur-sm text-white font-bold py-3 rounded-lg hover:bg-white/30 transition duration-200"
  onClick={handleClaimCashback}
>
  Redeem
</button>
      </div>

      <div className="mt-8 w-full max-w-md">
  <h2 className="text-xl font-bold mb-4 text-gray-800">Recent Transactions</h2>
  <div className="space-y-4">
  {transactions.length === 0 ? (
      <div className="min-h-[40px] flex items-center justify-center border border-[#F85D5A] rounded-lg">
        <p className="text-center text-lg font-bold text-gray-600">No transactions yet</p>
      </div>
    ):(
    transactions.map((transaction, index) => (
      <div 
      key={index}
      className="bg-white rounded-lg p-4 cursor-pointer hover:shadow-md transition-shadow duration-200"
      onClick={() => navigate('/consumer/transaction-details', { 
        state: { transaction } 
      })}
    >
       <div className="flex items-center space-x-4">
  <div className={`${getSequentialColor(index)} w-10 h-10 rounded-lg flex items-center justify-center text-white font-bold`}>
    {transaction?.partnerName.charAt(0)}
  </div>
  
  <div className="flex-1">
    <h3 className="font-medium text-gray-800 text-xl">{transaction?.partnerName}</h3>
    <p className="text-sm text-gray-500">{new Date(transaction?.transactionDate).toLocaleDateString()}</p>
  </div>
  
  <div className={`font-bold ${transaction?.transactionType === 'credit' ? 'text-green-600' : 'text-red-500'}`}>
    {transaction?.transactionType === 'credit' ? '+' : '-'}{transaction?.buildCoins} coins
  </div>
</div>

        <p className="text-sm text-gray-600 mt-2">{transaction.description}</p>
      </div>
    ))
    )}
  </div>
</div>


<div className="mt-20 w-full max-w-xl">
  <Menu />
</div>


      {/* Modal */}
      {isModalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
{!showQR ? (
  <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full relative">
    <button
      onClick={closeModal}
      className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>

    <h2 className="text-xl font-bold mb-4">Redeem</h2>
    
    <div className="space-y-4">
      {eligibility.length === 0 ? (
        <div className="text-center py-4 text-gray-600">
          No coins available to claim
        </div>
      ) : (
        <>
          {eligibility.map((item, index) => (
            <div key={index} className="border-b pb-4 last:border-b-0">
              <div className="flex items-center mb-2">
                <input
                  type="radio"
                  id={`item-${index}`}
                  name="eligibility"
                  className="mr-3"
                  onChange={() => {
                    setSelectedItem(item);
                  }}
                />
                <div className="flex-1">
                  <div className="flex justify-between items-center">
                    <span className="font-medium">{item.partnerName}</span>
                    <span className="font-bold text-green-600">{item.coins} coins</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {eligibility.length > 0 && (
            <button
              className="w-full bg-[#F85D5A] text-white font-bold py-2 rounded-lg hover:bg-[#E74D4A] transition duration-200"
              onClick={() => selectedItem && setShowQR(true)}
              disabled={!selectedItem}
            >
              Redeem Selected Coins
            </button>
          )}
        </>
      )}
    </div>
  </div>
    ) : (
      <div className="bg-white p-6 rounded-lg shadow-lg relative">
    <button
onClick={() => {
  setShowQR(false);
  closeModal();
}}
      className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
    <div className="p-4">
      {qrLoading && (
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#F85D5A]">
{/*             <svg className="w-8 h-8 text-[#F85D5A]" fill="none" viewBox="0 0 24 24">
              <path d="M12 4.75v1.5M17.127 6.873l-1.061 1.061M19.25 12h-1.5M17.127 17.127l-1.061-1.061M12 17.75v1.5M7.873 17.127l1.061-1.061M4.75 12h1.5M7.873 6.873l1.061 1.061" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg> */}
{/*             <img src={'logo.jpg'} alt="Loading" className="w-12 h-12" />
 */}          </div>
        </div>
      )}
<img 
  src={`http://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodeURIComponent(`http://192.168.29.166:3000/consumer/claim-reward?consumerId=${consumerId}&partnerId=${selectedItem.partnerId}&buildCoins=${selectedItem.coins}&coinLedgerId=${selectedItem.coinLedgerId}&consumerName=${consumerName}`)}`}
  alt="QR Code"
  onLoad={() => setQrLoading(false)}
  className={qrLoading ? 'hidden' : 'block'}
/>


    </div>
  </div>
    )}
  </div>
)}

{showInfoPopup && (
  <>
    {/* Overlay */}
    <div 
      className="fixed inset-0 bg-black bg-opacity-20 z-40"
      onClick={() => setShowInfoPopup(false)}
    />
    
    {/* Bottom Sheet */}
    <div className="fixed inset-x-0 bottom-2 p-2 z-50  transform transition-transform duration-300 ease-out">
      <div className="bg-white rounded-2xl p-4">
        {/* Pull indicator */}
        <div className="w-12 h-1.5 bg-gray-300 rounded-full mx-auto mb-6" />
        
        {/* Content */}
        <div className="px-4">
          <div className="flex items-center gap-3 mb-2">
            
            <h3 className="text-3xl font-bold">On the way Coins</h3>
          </div>
          
          <p className="text-gray-600 text-base leading-relaxed mb-4">
            On the way coins are earned on buildcoins transactions,That are to be approved by the authorized partner for redemption.
          </p>
          
          <button 
            onClick={() => setShowInfoPopup(false)}
            className="w-full bg-[#F85D5A] text-white py-4 rounded-medium font-bold text-lg mb-4"
          >
           Okay, got it
          </button>
        </div>
      </div>
    </div>
  </>
)}

{showLifetimeInfoPopup && (
  <>
    {/* Overlay */}
    <div 
      className="fixed inset-0 bg-black bg-opacity-20 z-40"
      onClick={() => setShowLifetimeInfoPopup(false)}
    />
    
    {/* Bottom Sheet */}
    <div className="fixed inset-x-0 bottom-2 p-2 z-50  transform transition-transform duration-300 ease-out">
      <div className="bg-white rounded-2xl p-4">
        {/* Pull indicator */}
        <div className="w-12 h-1.5 bg-gray-300 rounded-full mx-auto mb-6" />
        
        {/* Content */}
        <div className="px-4">
          <div className="flex items-center gap-3 mb-2">
            
            <h3 className="text-3xl font-bold">Lifetime Coins</h3>
          </div>
          
          <p className="text-gray-600 text-base leading-relaxed mb-4">
           Life time coins are the coins that you have earned with us throughout your journey with us.
          </p>
          
          <button 
            onClick={() => setShowLifetimeInfoPopup(false)}
            className="w-full bg-[#F85D5A] text-white py-4 rounded-medium font-bold text-lg mb-4"
          >
           Okay, got it
          </button>
        </div>
      </div>
    </div>
  </>
)}

{showRedeemedInfoPopup && (
  <>
    {/* Overlay */}
    <div 
      className="fixed inset-0 bg-black bg-opacity-20 z-40"
      onClick={() => setShowRedeemedInfoPopup(false)}
    />
    
    {/* Bottom Sheet */}
    <div className="fixed inset-x-0 bottom-2 p-2 z-50  transform transition-transform duration-300 ease-out">
      <div className="bg-white rounded-2xl p-4">
        {/* Pull indicator */}
        <div className="w-12 h-1.5 bg-gray-300 rounded-full mx-auto mb-6" />
        
        {/* Content */}
        <div className="px-4">
          <div className="flex items-center gap-3 mb-2">
            
            <h3 className="text-3xl font-bold">Redeemed Coins</h3>
          </div>
          
          <p className="text-gray-600 text-base leading-relaxed mb-4">
          Redeemed coins are coins that have been redeemed by you at our authorized partners.
          </p>
          
          <button 
            onClick={() => setShowRedeemedInfoPopup(false)}
            className="w-full bg-[#F85D5A] text-white py-4 rounded-medium font-bold text-lg mb-4"
          >
           Okay, got it
          </button>
        </div>
      </div>
    </div>
  </>
)}


{showRedeemableInfoPopup && (
  <>
    {/* Overlay */}
    <div 
      className="fixed inset-0 bg-black bg-opacity-20 z-40"
      onClick={() => setShowRedeemableInfoPopup(false)}
    />
    
    {/* Bottom Sheet */}
    <div className="fixed inset-x-0 bottom-2 p-2 z-50  transform transition-transform duration-300 ease-out">
      <div className="bg-white rounded-2xl p-4">
        {/* Pull indicator */}
        <div className="w-12 h-1.5 bg-gray-300 rounded-full mx-auto mb-6" />
        
        {/* Content */}
        <div className="px-4">
          <div className="flex items-center gap-3 mb-2">
            
            <h3 className="text-3xl font-bold">Redeemable Coins</h3>
          </div>
          
          <p className="text-gray-600 text-base leading-relaxed mb-4">
            Redeemable coins are coins that are available to redeem at our authorized partners.
          </p>
          
          <button 
            onClick={() => setShowRedeemableInfoPopup(false)}
            className="w-full bg-[#F85D5A] text-white py-4 rounded-medium font-bold text-lg mb-4"
          >
           Okay, got it
          </button>
        </div>
      </div>
    </div>
  </>
)}


</div>
    </div>
  );
}

export default Home;
