import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Menu from './menu';
import { FaSignal, FaWifi, FaBatteryFull, FaUserCircle, FaBell, FaMoneyBillWave, FaWallet, FaQrcode, FaCreditCard, FaPiggyBank, FaGift, FaHistory, FaHandPointer } from "react-icons/fa";

function UploadInvoice() {
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file); // Store the actual file instead of URL
    }
  };
  
  const handleUploadInvoice = async () => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('image', selectedImage);
  
      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}rewards/add-customer-invoice`, {
        method: 'POST',
        body: formData
      });
  
      const data = await response.json();
      console.log(data);
      if (data) {
        setIsModalOpen(true);
      }
    } catch (err) {
      setError('Failed to upload invoice');
    } finally {
      setIsUploading(false);
    }
  };
  

  return (
<div className="fixed inset-0 bg-gradient-to-br from-[#F85D5A] via-[#E54542] to-[#D12D2A] overflow-hidden">
  <label className="fixed inset-0 flex flex-col items-center justify-center cursor-pointer">

        <input
          type="file"
          accept="image/*"
          className="hidden"
          onChange={handleFileUpload}
          capture="environment"
        />
        
        <div className="text-center text-white">
          <div className="mb-12">
            <div className="text-3xl font-bold mb-2">Scan Invoice</div>
            <div className="text-lg opacity-90">Align invoice within the frame</div>
          </div>
          
          {/* Square Camera Frame Guide */}
          <div className="relative w-72 h-72 mx-auto">
            {/* Outer decorative frame */}
            <div className="absolute -inset-4 border-2 border-white opacity-20 rounded-lg"></div>
            
            {/* Main scanning frame */}
            <div className="relative w-full h-full">
              {/* Corner markers */}
              <div className="absolute top-0 left-0 w-10 h-10 border-t-4 border-l-4 border-white"></div>
              <div className="absolute top-0 right-0 w-10 h-10 border-t-4 border-r-4 border-white"></div>
              <div className="absolute bottom-0 left-0 w-10 h-10 border-b-4 border-l-4 border-white"></div>
              <div className="absolute bottom-0 right-0 w-10 h-10 border-b-4 border-r-4 border-white"></div>
              
              {/* Scanning animation */}
              <div className="absolute inset-0 overflow-hidden">
                <div className="h-1.5 bg-white/50 w-full transform translate-y-0 animate-scan"></div>
              </div>
              
              <div className="absolute inset-0 flex items-center justify-center">
                <FaHandPointer className="text-4xl text-white/90 animate-bounce" />
              </div>
            </div>
          </div>
          
          <div className="mt-12 text-lg font-medium">
            Tap to capture invoice
          </div>
        </div>
      </label>

      {/* Preview and Upload UI */}
      {selectedImage && (
        <div className="fixed inset-0 bg-white z-50">
          <div className="h-full flex flex-col p-4">
            <img 
              src={URL.createObjectURL(selectedImage)} 
              alt="Invoice preview" 
              className="flex-1 object-contain mb-4"
            />
            <div className="flex gap-3">
              <button 
                className={`flex-1 bg-[#F85D5A] text-white py-4 rounded-xl text-lg font-semibold ${
                  isUploading ? 'opacity-50' : ''
                }`}
                onClick={handleUploadInvoice}
                disabled={isUploading}
              >
                {isUploading ? 'Processing...' : 'Confirm & Upload'}
              </button>
              <button 
                className="flex-1 border-2 border-[#F85D5A] text-[#F85D5A] py-4 rounded-xl text-lg font-semibold"
                onClick={() => setSelectedImage(null)}
              >
                Retake
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Success Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-xl m-4 w-full max-w-sm">
            <h3 className="text-2xl font-bold mb-3">Success!</h3>
            <p className="mb-6 text-gray-600">Your invoice has been uploaded successfully.</p>
            <button
              className="w-full bg-[#F85D5A] text-white py-4 rounded-xl text-lg font-semibold"
              onClick={() => {
                setIsModalOpen(false);
                setSelectedImage(null);
              }}
            >
              Done
            </button>
          </div>
        </div>
      )}
    

      <div className="mt-20 w-full max-w-xl">
        <Menu />
      </div>
    </div>
  );
}

export default UploadInvoice;
