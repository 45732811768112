import React from 'react';
import { CIcon } from '@coreui/icons-react';
import { 
  cilHome,
  cilWallet,
  cilQrCode,
  cilGift,
  cilUser
} from '@coreui/icons';
import { useNavigate } from 'react-router-dom';

const Menu = () => {
  const navigate = useNavigate();

  const handleHome = () => {
    navigate('/consumer/home');
  };

  const handleWallet = () => {
    navigate('/consumer/wallet');
  };

  const handleScan = () => {
    navigate('/consumer/upload-invoice');
  };

  const handleRewards = () => {
    navigate('/consumer/home');
  };

  const handleProfile = () => {
    navigate('/consumer/profile');
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 flex justify-between items-center border-t border-gray-200 py-2 px-4 bg-white shadow-lg">
      <button onClick={handleHome} className="flex flex-col items-center gap-1">
        <CIcon icon={cilHome} className="w-6 h-6 text-gray-600" />
        <span className="text-xs font-medium text-gray-600">Home</span>
      </button>

      <button onClick={handleWallet} className="flex flex-col items-center gap-1">
        <CIcon icon={cilWallet} className="w-6 h-6 text-gray-600" />
        <span className="text-xs font-medium text-gray-600">Cards</span>
      </button>

      <button onClick={handleScan} className="flex flex-col items-center gap-1">
        <div className="bg-[#F85D5A] p-3 rounded-full -mt-5">
          <CIcon icon={cilQrCode} className="w-6 h-6 text-white" />
        </div>
        <span className="text-xs font-medium text-gray-600">Scan</span>
      </button>

      <button onClick={handleRewards} className="flex flex-col items-center gap-1">
        <CIcon icon={cilGift} className="w-6 h-6 text-gray-600" />
        <span className="text-xs font-medium text-gray-600">Offers</span>
      </button>

      <button onClick={handleProfile} className="flex flex-col items-center gap-1">
        <CIcon icon={cilUser} className="w-6 h-6 text-gray-600" />
        <span className="text-xs font-medium text-gray-600">Profile</span>
      </button>
    </div>
  );
};

export default Menu;
